import CMRI from "../assets/logos/cmri-hospita.png";
import ARTEMIS from "../assets/logos/si-artemis.png";
import BMBIRLA from "../assets/logos/bm-birla.png";
import CHARUHEALTH from "../assets/logos/charu-health.png";
import GOLFVIEW from "../assets/logos/golfview.png";
import HSCLINIC from "../assets/logos/hs-clinic.png";
import IRIS from "../assets/logos/iris-hospital.png";
import PASHUPATINATH from "../assets/logos/pashupatinath.png";
import RAINBOW from "../assets/logos/Rainbow.png";
import RAMESWARA from "../assets/logos/rameswara.png";
import SEBABRATO from "../assets/logos/sebabrato-hospital.png";
import GLOCALHEALTHCARE from "../assets/logos/glocal-healthcare.png";
import GNPM from "../assets/logos/gnpm.png";
import HEARTLAND from "../assets/logos/heartland.png";
import HHP from "../assets/logos/hhp.png";
import KOLKATAMEDICALCENTER from "../assets/logos/kolkata-medical-cemter.png";
import LABBAIK from "../assets/logos/labbaik.png";
import MCS from "../assets/logos/mcs.png";
import NIRAMAYA from "../assets/logos/niramaya.png";
import OCTAVE from "../assets/logos/octave-healthtech.png";
import PILLARHEALTH from "../assets/logos/pillar-health.png";
import ROMALINDA from "../assets/logos/romalinda.png";
import SUNHOSPITAL from "../assets/logos/sun-hospital.png";
import SWASTHYA from "../assets/logos/swasthya.png";
import TRA from "../assets/logos/TRA.png";
import UMRI from "../assets/logos/UMRI.png";
import HCG from "../assets/logos/HCG.png";
import Goodace from "../assets/logos/goodace.png";
import North_City from "../assets/logos/north-city.png";
 


const data = [
    { img: CMRI, location: "KOLKATA" },
    { img: ARTEMIS, location: "KOLKATA" },
    { img: BMBIRLA, location: "KOLKATA" },
    { img: North_City, location: "KOLKATA" },
    { img: HCG, location: "KOLKATA" },
    { img: GOLFVIEW, location: "KOLKATA" },
    { img: IRIS, location: "KOLKATA" },
    { img: RAMESWARA, location: "KOLKATA" },
    { img: GNPM, location: "KOLKATA" },
    { img: HEARTLAND, location: "KOLKATA" },
    { img: HHP, location: "KOLKATA" },
    { img: KOLKATAMEDICALCENTER, location: "KOLKATA" },
    { img: LABBAIK, location: "KOLKATA" },
    { img: NIRAMAYA, location: "KOLKATA" },
    { img: PILLARHEALTH, location: "KOLKATA" },
    { img: ROMALINDA, location: "KOLKATA" },
    { img: TRA, location: "KOLKATA" },
    { img: UMRI, location: "KOLKATA" },
   
    { img: Goodace, location: "KOLKATA" },

    { img: MCS, location: "HOWRAH" },
    { img: HSCLINIC, location: "KALYANI" },
    { img: PASHUPATINATH, location: "KALYANI" },
    { img: RAINBOW, location: "KALYANI" },
    { img: OCTAVE, location: "KANCHRAPARA" },
    { img: SEBABRATO, location: "ULUBERIA" },
    { img: CHARUHEALTH, location: "CHAKDAHA" },
    { img: SUNHOSPITAL, location: "BARDHAMAN" },
    { img: SWASTHYA, location: "BARDHAMAN" },
    { img: GLOCALHEALTHCARE, location: "KRISHNANAGAR, SONAMUKHI, MIDNAPORE" },
    


    
];



export default data;   