import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../styles/Partners.module.css";
import { RiArrowDropRightLine } from "react-icons/ri";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { useState } from 'react';
import { useEffect } from 'react';
import styles from "../styles/Partners.module.css";
import stylesRes from "../styles/PartnersResponsive.module.css";
import PartnersImg from "../Data/PartnersImg.js"

const PreviousBtn = (props) => {

  const {onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <div className={[`${styles.nextarrow}`].join(" ")} onClick={onClick}>
          <RiArrowDropRightLine  className={[`${stylesRes.icon}`].join(" ")} color='#19717D' size={100}/>
        </div>
      )}
    </>
  );
};
const Nextarrow = (props) => {
  const {onClick, slideCount, currentSlide } = props;
  return (
    <>
      {currentSlide !== slideCount - slidesToShow && (
        <div  className={[`${styles.prevarrow}`].join(" ")} onClick={onClick}>
          <RiArrowDropLeftLine className={[`${stylesRes.icon}`].join(" ")} color='#19717D' size={100}/>
        </div>
      )}
    </>
  );
};

let slidesToShow = 5;

const carouselProperties = {
  prevArrow: <PreviousBtn />,
  nextArrow: <Nextarrow />,
  slidesToShow: slidesToShow,
  slidesToScroll: 2,
  infinite: false,
 
  responsive: [
    {
      breakpoint: 426,
      settings: {
        slidesToShow: 1,
        centerMode: false,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 3,
        centerMode: false,
      },
    },
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
        centerMode: false,
        slidesToScroll: 2,
      },
    },
  ],
};

const Partners = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  if (width <= 426) {
    slidesToShow = 1;
  } else if (width > 426 && width <= 769) {
    slidesToShow = 3;
  } else if (width > 769 && width <= 1025) {
    slidesToShow = 4;
  } else {
    slidesToShow = 5;
  }


  return (
    <section className={[`${styles.carousel}`, `${stylesRes.carousel}`, ].join(" ")}>
       <div className="hospital-header">
        <p className="hospital-title">OUR ESTEEMED PARTNERS</p>
        <p className="hospital-subtitle">
          Expanding our reach by joining forces with hospitals and corporates
        </p>
      </div>
      <Slider className={[`${styles.slider}`].join(" ")} {...carouselProperties}>
        {PartnersImg.map((item) => (
          <Card item={item} />
        ))}
      </Slider>
    </section>
  );
};

const Card = ({ item }) => {
  return (
    <div className={[`${styles.partnersblock}`].join(" ")}style={{ textAlign: 'center' }}>
      <img
        className={[`${styles.multi__image}`,`${stylesRes.multi__image}`].join(" ")}
        src={item.img}
        alt={item.img}
      />
      <h1  className={[`${styles.title}`, `${stylesRes.title}`].join(" ")}>{item.location}</h1>
    </div>
  );
};

export default Partners;

