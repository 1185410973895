import Service1 from "../assets/service1.png";
import Service2 from "../assets/service2.png";
import Service3 from "../assets/service3.png";
import Service4 from "../assets/service4.png";
import Service5 from "../assets/service5.png";

const servicesData = [
  {
    heading: "24x7 Ambulance Service",
    description: `AmbiQ connects you with the nearest available ambulance. The ambulance will take you to the nearest hospital or your desired medical facility.`,
    imgSrc: Service1,
  },
  {
    heading: "Live Hospital Bed Availability",
    description: `Users can access real-time information on available
                      hospital beds, helping them make informed decisions during
                      emergencies.`,
    imgSrc: Service2,
  },
 
  {
    heading: "24x7 Support",
    description: `A live section allows users to specify the type of support
                      they require, ensuring that ambulance services are
                      tailored to their needs.`,
    imgSrc: Service4,
  },
 
];

export default servicesData;
